//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import page from "page"
import queryString from 'query-string'
import Notebook from './Notebook.vue'

export default {
  name: 'Book',
  async mounted() {
    if(!this.$root.$data.kobo) {
      page('/')
      return;
    }

    this.db = this.$root.$data.kobo
    
    const parsed = queryString.parse(location.hash);
    console.debug(parsed)
    this.book_id = parsed ? parsed["!/notes/retrieve/?book_id"] : null
    this.fetchBookTitle()

    this.mounted = true;
  },
  data: function() {
    return {
        book_title: '',
        mounted: false,
        book_id: null
    }
  },
  computed: {
  },
  methods: {
    fetchBookTitle() {
        let query = `select b.BookTitle as book_title from Content as b
        where b.BookID in ('${ this.book_id }')
        limit 1
        `
        const res = this.db.exec(query);

        this.book_title = res.length !== 1 ? '' : res[0].values[0][0] // 1st row, 1st col

        console.debug(res);
    },
  },
  components: {
    Notebook,
  },
}
